import {createRouter, createWebHistory} from 'vue-router'


// import Home from '../views/Home'
// import Salons from '../views/Salons'
// import GiveTips from '../views/GiveTips'
// import GiveTipsMoreInfo from '../views/GiveTipsMoreInfo'
// import TipWithoutScanning from '../views/TipWithoutScanning'
// import GetTips from '../views/GetTips'
// import GetTipsMoreInfo from '../views/GetTipsMoreInfo'
// import GetTipsStart from '../views/GetTipsStart'
// import Contact from '../views/Contact'
// import About from '../views/About'
// import Terms from '../views/Terms'
// import PrivacyPolicy from '../views/Privacy'
// import Testimonials from '../views/Testimonials'
// import Unsubscribe from '../views/Unsubscribe'

// const Parse = require('parse/node');
// import sharedhome from '../sharedhome.js';
// import appComponent from '../App.vue';


const routes = [
    {
      name: "Home",
      path: "/",
      component: () => import('../views/Home')
    },
    {
      name: "Callback",
      path: "/callback",
      component: () => import('../views/Callback')
    },
    {
      name: "Salons",
      path: "/salons",
      component: () => import('../views/Salons')
    },
    {
      name: "GiveTips",
      path: "/givetips",
      component: () => import('../views/GiveTips')
    },
    {
      name: "GiveTipsMoreInfoNew",
      path: "/givetipsmoreinfonew",
      component: () => import('../views/GiveTipsMoreInfoNew')
    },
    {
      name: "GiveTipsMoreInfo",
      path: "/givetipsmoreinfo",
      component: () => import('../views/GiveTipsMoreInfo')
    },
    {
      name: "TipWithoutScanning",
      path: "/tipwithoutscanning",
      component: () => import('../views/TipWithoutScanning')
    },
    {
      name: "GetTips",
      path: "/gettips",
      component: () => import('../views/GetTips')
    },
    {
      name: "GetTipsMoreInfoNew",
      path: "/gettipsmoreinfonew",
      component: () => import('../views/GetTipsMoreInfoNew')
    },
    {
      name: "GetTipsMoreInfo",
      path: "/gettipsmoreinfo",
      component: () => import('../views/GetTipsMoreInfo')
    },
    {
      name: "GetTipsStartNew",
      path: "/gettipsstartnew",
      component: () => import('../views/GetTipsStartNew')
    },
    {
      name: "GetTipsStart",
      path: "/gettipsstart",
      component: () => import('../views/GetTipsStart')
    },
    {
      name: "ContactNew",
      path: "/contactnew",
      component: () => import('../views/ContactNew')
    },
    {
      name: "AboutNew",
      path: "/aboutnew",
      component: () => import('../views/AboutNew')
    },
    {
      name: "TermsNew",
      path: "/termsnew",
      component: () => import('../views/TermsNew')
    },
    {
      name: "PrivacyNew",
      path: "/privacynew",
      component: () => import('../views/PrivacyNew')
    },
    {
      name: "Contact",
      path: "/contact",
      component: () => import('../views/Contact')
    },
    {
      name: "About",
      path: "/about",
      component: () => import('../views/About')
    },
    {
      name: "Terms",
      path: "/terms",
      component: () => import('../views/Terms')
    },
    {
      name: "Privacy",
      path: "/privacy",
      component: () => import('../views/Privacy')
    },
    {
      name: "Testimonials",
      path: "/testimonials",
      component: () => import('../views/Testimonials')
    },
    {
      name: "Unsubscribe",
      path: "/unsubscribe",
      component: () => import('../views/Unsubscribe')
    }
    
    // ...
]

// const router = createRouter({
//     mode: createWebHistory(process.env.BASE_URL), routes,
//     base: "/",
//     routes: 
// });

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL), routes
})

router.beforeEach(
    (to, from, next) => {
        
        let urlParams = new URLSearchParams(window.location.search);
        // console.log("router hould have urlparams coming...");
        // console.log("urlParams:: " + urlParams);

        if (urlParams.has('linkname')){
          if (urlParams.get('linkname') === "ggreview"){
            
            // console.log("action link follow!");
            // console.log("SHOULD OPEN!");
            window.location.replace("https://g.page/r/CQEvdh98ctRyEAI/review");
             
            next();
            return true;
          } else {
            // console.log("SOME OTHER LINK!");
            next()
            return true;
          }
        } else {
          // console.log("NO link follow!");
          next()
          return true;
        }

    }
  )

export default router

